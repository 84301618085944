import { Spinner } from "@blueprintjs/core";

const FullPageLoader = () => {
  return (
    <div className="h-screen w-screen absolute flex justify-center items-center">
      <Spinner />
    </div>
  );
};

export default FullPageLoader;