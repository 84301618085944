import { BasePageEvent } from "./common";

export class LandingPageEvent extends BasePageEvent {
	category = "LandingPage";

	downloadApp({ label }: { label: "apple" | "android" }) {
		this.event({
			category: this.category,
			action: "download_app_clicked",
			label,
			nonInteraction: false,
		});
	}
}
