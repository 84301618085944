import React, { Suspense, useContext, useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "./App.css";
import FullPageLoader from "./components/full-page-loader.component";
import URLS from "./util/urls";
import { Analytics } from "./util/google-analytics";
import UserContext, { UserContextProvider } from "./contexts/user.context";

const LandingPage = React.lazy(() => import("./pages/landing.page"));
const BusinessPage = React.lazy(() => import("./pages/business.page"));
const AvailabilitiesPage = React.lazy(
	() => import("./pages/availabilities.page")
);
const EditBookingPage = React.lazy(
	() => import("./pages/edit-booking.page")
);
const CompletedPage = React.lazy(() => import("./pages/completed.page"));
const ErrorBoundaryPage = React.lazy(
	() => import("./pages/error-boundary.page")
);
const EmailVerificationCompletePage = React.lazy(
	() => import("./pages/email-verification-complete.page")
);
const PaymentFailedPage = React.lazy(
	() => import("./pages/payment-failed.page")
);
const PaymentTermsPage = React.lazy(
	() => import("./pages/payment-terms.page")
);

const urls = new URLS();

const withSuspense = (Component: any) => {
	return (
		<Suspense fallback={<FullPageLoader />}>
			<Component />
		</Suspense>
	);
};

const router = createBrowserRouter([
	{
		path: "/",
		element: withSuspense(LandingPage),
		errorElement: withSuspense(ErrorBoundaryPage),
	},
	{
		path: "/s/verification-complete",
		element: withSuspense(EmailVerificationCompletePage),
	},
	{
		path: "/s/payment-failed",
		element: withSuspense(PaymentFailedPage),
	},
	{
		path: "/t/payment-terms",
		element: withSuspense(PaymentTermsPage),
	},
	{
		path: urls.business().definition,
		element: withSuspense(BusinessPage),
		errorElement: withSuspense(ErrorBoundaryPage),
	},
	{
		path: urls.availabilities().definition,
		element: withSuspense(AvailabilitiesPage),
		errorElement: withSuspense(ErrorBoundaryPage),
	},
	{
		path: urls.completed().definition,
		element: withSuspense(CompletedPage),
		errorElement: withSuspense(ErrorBoundaryPage),
	},
	{
		path: urls.editBooking().definition,
		element: withSuspense(EditBookingPage),
		errorElement: withSuspense(ErrorBoundaryPage),
	},
]);

const App = () => {
	const { userId } = useContext(UserContext);

	useEffect(() => {
		Analytics.initializeAnalytics()
	}, [])

	useEffect(() => {
		if (userId) {
			Analytics.setUserId(userId)
		}
	}, [userId])

	return (
		<UserContextProvider>
			<RouterProvider router={router} />
		</UserContextProvider>
	);
};

export default App;
