import { ReactNode, createContext, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from 'uuid';

import Storage, { CookieStorage } from "../util/storage";

type UserContextProps = {
	children: ReactNode;
};

interface UserContextValue {
  userId?: string
}

const UserContext = createContext<UserContextValue>({});
const storage = new Storage(new CookieStorage())
const TEN_YEARS_IN_DAYS = 10 * 364

export const UserContextProvider = ({ children }: UserContextProps) => {
  const [userId, setUserId] = useState(storage.get("uid"))

  useEffect(() => {
    if (!userId) {
      const id = uuidv4();

      storage.set("uid", id, TEN_YEARS_IN_DAYS);
      setUserId(id);
    }
  }, [userId])

  const value = useMemo(() => ({ userId }), [userId])
  
  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  )
}

export default UserContext;