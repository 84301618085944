import { BasePageEvent } from "./common";

export class BusinessPageEvent extends BasePageEvent {
	category = "BusinessPage";

	selectService({ label }: { label: string }) {
		this.event({
			action: "select_service",
			label,
		});
	}
}
