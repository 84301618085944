const setCookie = (cname: string, cvalue: string, exdays: number = 0) => {
	const d = new Date();

	d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
	const expires = "expires=" + d.toUTCString();
	document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

const getCookie = (cname: string) => {
	const name = cname + "=";
	const decodedCookie = decodeURIComponent(document.cookie);
	const ca = decodedCookie.split(";");

	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) == " ") {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}

	return "";
};

type CookieName = "uid";

interface BaseStorage {
	get: (name: CookieName) => string;
	set: (key: CookieName, value: string, expiryInDays: number) => void;
	delete: (key: CookieName) => void;
}

export class CookieStorage implements BaseStorage {
	delete(key: CookieName) {
		return setCookie(key, "");
	}

	get(name: CookieName): string {
		return getCookie(name);
	}

	set(key: CookieName, value: string, expiryInDays: number) {
		return setCookie(key, value, expiryInDays);
	}
}

export default class Storage {
	private storage: BaseStorage;

	constructor(storage: BaseStorage) {
		this.storage = storage;
	}

	get(name: CookieName): string | undefined {
		return this.storage.get(name);
	}

	set(key: CookieName, value: string, expiryInDays: number) {
		return this.storage.set(key, value, expiryInDays);
	}

	delete(name: CookieName): void {
		return this.storage.delete(name);
	}
}
