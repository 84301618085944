import { BasePageEvent } from "./common";

export class AvailabilitiesPageEvent extends BasePageEvent {
	category = "AvailabilitiesPage";

	addService() {
		this.event({
			action: "add_service",
			label: "Add service Pressed",
		});
	}

	fillBookingInfo() {
		this.event({
			action: "fill_booking_info",
			label: "Fill in booking info",
		});
	}

	bookingRequested() {
		this.event({
			action: "booking_requested",
			label: "Booking requested",
		});
	}

	bookingSuccess() {
		this.event({
			action: "booking_success",
			label: "Booking passed",
		});
	}

	bookingFailure(message: string) {
		this.event({
			action: "booking_failure",
			label: message,
		});
	}
}
