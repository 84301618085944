import ReactGA from "react-ga4";
import { Analytics, TrackCustomEvent } from "..";

interface TrackCustomEventProps {
	category?: string;
	action: string;
	label: string;
	value?: number;
	nonInteraction?: boolean;
	params?: Record<string, string>;
}

export abstract class BasePageEvent {
	protected category: string = "default";

	event(props: TrackCustomEventProps) {
		const { category, action, label, value, nonInteraction, params } = props;

		const event = {
			category: category ?? this.category,
			action,
			label,
			value,
			nonInteraction,
			user_id: TrackCustomEvent.userId,
		};

		if (Analytics.initialized) {
			if (params) {
				ReactGA.event({
					...event,
					...params,
				});
			} else {
				ReactGA.event(event);
			}
		}
	}
}
