import ReactGA from "react-ga4";

import * as config from "../../config";
import { BusinessPageEvent } from "./events/business-page";
import { LandingPageEvent } from "./events/landing-page";
import { AvailabilitiesPageEvent } from "./events/availabilities-page";

export class Analytics {
	private static isInitialized = false;

	static get initialized() {
		return this.isInitialized;
	}

	static initializeAnalytics() {
		try {
			this.isInitialized = !!config.GOOGLE_ANALYTICS;

			ReactGA.initialize(config.GOOGLE_ANALYTICS || "");

			console.log("Analytics Initialized");
		} catch (e) {
			console.log("Error Initializing Analytics");
		}
	}

	static setUserId(userId: string) {
		console.log("Initializing ", userId);
		TrackCustomEvent.userId = userId;
		ReactGA.set({ userId });
	}
}

export class TrackCustomEvent {
	static userId: string | undefined;

	static get availabilitiesPage() {
		return new AvailabilitiesPageEvent();
	}

	static get businessPage() {
		return new BusinessPageEvent();
	}

	static get landingPage() {
		return new LandingPageEvent();
	}
}
