type Result = {
	url: string;
	definition: string;
};

export default class URLS {
  businessSlug?: string;

  withBusinessSlug(slug: string) {
    this.businessSlug = slug;
    
    return this;
  }

	availabilities(slug?: string): Result {
		const definition = "/:businessSlug/availabilities";
		return {
			definition,
			url: definition.replace(":businessSlug", slug || ""),
		};
	}

	business(slug?: string): Result {
		const definition = "/:businessSlug";

		return {
			definition,
			url: definition.replace(":businessSlug", slug || ""),
		};
	}
	completed(slug?: string): Result {
		const definition = "/:businessSlug/completed";

		return {
			definition,
			url: definition.replace(":businessSlug", slug || ""),
		};
	}

	editBooking(slug?: string): Result {
		const definition = "/:businessSlug/booking/edit";

		return {
			definition,
			url: definition.replace(":businessSlug", slug || ""),
		};
	}
}
